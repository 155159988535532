import React from "react";
import SEO from "../../../../components/seo";
import SharePriceDataWidget from "../../../../components/SharePriceDataWidget";

function PriceMiniWidget() {
  return (
    <>
      <SEO />
      <SharePriceDataWidget isMini />
    </>
  );
}
export default PriceMiniWidget;
